.Logo {
    background-color: #890c08;
    padding: 1px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
}

.Logo img {
    height: 100%;
}
